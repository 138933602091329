























































































































































import { SITE_DIRECTION } from "@/store/modules/common/constants";
import Vue from "vue";
import { mapGetters } from "vuex";
import ChangeLanguage from "@/components/shared/ChangeLanguage.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
// import AppPoweredBy from "@/components/shared/AppPoweredBy.vue";
export default Vue.extend({
  name: "AuthenticationDashboard",
  components: { ChangeLanguage },
  data() {
    return {
      dash_images: [
        // temporary
        require("@/assets/images/onboarding_decoration/browsers-inception-1.png"),
        require("@/assets/images/onboarding_decoration/browsers-inception-2.png"),
        require("@/assets/images/onboarding_decoration/browsers-inception-3.png")
      ] as string[],
      active_img: "",
      index: 0
    };
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  mounted() {
    this.active_img = this.dash_images[this.index];
  },
  methods: {
    /**
     * Change decoration picture on left/right icon click
     * @param direction{number}  => 0: if left icon btn pressed
     *                             1: if right icon btn pressed
     */
    change_picture(direction: number) {
      const decoration_image = this.$refs.img as HTMLImageElement;
      // Add transition effect class
      decoration_image.classList.add("transitioning-opacity");
      if (direction) {
        if (this.index === 2) this.index = 0;
        else this.index += 1;
      } else {
        if (this.index === 0) this.index = 2;
        else this.index -= 1;
      }
      this.active_img = this.dash_images[this.index];
      // Remove transition effect class
      setTimeout(
        () => decoration_image.classList.remove("transitioning-opacity"),
        500
      );
    },
    /**
     * Change image on dot icons click
     * @param index Index of dot pressed
     */
    update_dot(index: number) {
      const decoration_image = this.$refs.img as HTMLImageElement;
      // Add transition effect class
      decoration_image.classList.add("transitioning-opacity");
      this.index = index;
      this.active_img = this.dash_images[this.index];
      // Remove transition effect class
      setTimeout(
        () => decoration_image.classList.remove("transitioning-opacity"),
        500
      );
    }
  }
});
